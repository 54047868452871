import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "optical-care",
  initialState: {
    activeOpticalCareRequestList: [],
    pendingOpticalCareList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedUserOpticalCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.activeOpticalCareRequestList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedPendingUserOpticalCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.pendingOpticalCareList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedUserOpticalCareRequestStart: (user, action) => {
      user.loading = true;
    },
    fetchedUserOpticalCareRequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

const {
  fetchedUserOpticalCareRequestSuccess,
  fetchedPendingUserOpticalCareRequestSuccess,
  fetchedUserOpticalCareRequestStart,
  fetchedUserOpticalCareRequestFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v1/admin/appointments/optical";

export const fetchOpticalCareUsersRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedUserOpticalCareRequestSuccess.type,
    onStart: fetchedUserOpticalCareRequestStart.type,
    onError: fetchedUserOpticalCareRequestFailed.type,
  });

export const fetchOpticalCareUserPendingRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingUserOpticalCareRequestSuccess.type,
    onStart: fetchedUserOpticalCareRequestStart.type,
    onError: fetchedUserOpticalCareRequestFailed.type,
  });

export const getOpticalCareUsers = (state) => state.entities.opticalCareUsers;

export const getPendingOpticalCareUser = (state) =>
  state.entities.opticalCareUsers.pendingOpticalCareList;

export const getPendingOpticalUserCareById = (state) => (id) =>
  state.entities.opticalCareUsers.pendingOpticalCareList.find(
    (e) => e.id === id
  );
