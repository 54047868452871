import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "otherPlan",
  initialState: {
    otherPlanList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedOtherPlanSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.otherPlanList = action.payload.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    
    fetchedOtherPlanStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    fetchedOtherPlanFailed: (enrollee, action) => {
      enrollee.loading = false;
    },
  },
});

const {
  fetchedOtherPlanSuccess,
  fetchedOtherPlanStart,
  fetchedOtherPlanFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v1/plans/purchase-for-others";

export const fetchOtherPlan = (pageItems, page) =>
  apiCallBegan({
    url: `${url}?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedOtherPlanSuccess.type,
    onStart: fetchedOtherPlanStart.type,
    onError: fetchedOtherPlanFailed.type,
  });


  



export const getOtherPlan = (state) => state.entities.enrolleeOtherPlan;

export const getPendingDrugRefillById = (state) => (id) =>
  state.entities.enrolleesDrugFill.pendingDrugFill.find(
    (drug) => drug.id === id
  );
