import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "cancer-care",
  initialState: {
    activeCancerCareRequestList: [],
    pendingCancerCareList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedCancerCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.activeCancerCareRequestList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedPendingCancerCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.pendingCancerCareList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedCancerCareRequestStart: (user, action) => {
      user.loading = true;
    },
    fetchedCancerCareRequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

const {
  fetchedCancerCareRequestSuccess,
  fetchedPendingCancerCareRequestSuccess,
  fetchedCancerCareRequestStart,
  fetchedCancerCareRequestFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v1/admin/appointments/cancer";

export const fetchCancerCareUsersRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedCancerCareRequestSuccess.type,
    onStart: fetchedCancerCareRequestStart.type,
    onError: fetchedCancerCareRequestFailed.type,
  });

export const fetchCancerCareUserPendingRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingCancerCareRequestSuccess.type,
    onStart: fetchedCancerCareRequestStart.type,
    onError: fetchedCancerCareRequestFailed.type,
  });

export const getCancerCareUsers = (state) => state.entities.cancerCareUsers;

export const getPendingCancerCareUser = (state) =>
  state.entities.cancerCareUsers.pendingCancerCareList;

export const getPendingCancerUserCareById = (state) => (id) =>
  state.entities.cancerCareUsers.pendingCancerCareList.find((e) => e.id === id);
