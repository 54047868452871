import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getEnrollees } from "../../../store/enrollee";
import Title from "../../common/Typography/title";
import { getFormatedDate } from "../../../utils/constants.js";
import styles from "./index.module.css";

const EnrolleeDependentDetails = () => {
  const enrollees = useSelector(getEnrollees);
  const enrollee = enrollees.data.enrollee_details
    ? enrollees.data.enrollee_details
    : {};
  const [showMore, setShowMore] = useState(false);
  const benefits = showMore
    ? enrollee.benefits
    : enrollee.benefits
    ? enrollee.benefits.slice(0, 6)
    : [];

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Primary Dependent (Spouse)</th>
          <th>Enrollee ID</th>
          <th>Age </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{enrollee.name}</td>
          <td>{enrollee.enrollee_id || "Nill"}</td>
          <td style={{ textTransform: "none" }}>{enrollee.email}</td>
        </tr>
      </tbody>
      <thead>
        <tr className={styles["table-row"]}>
          <th>Secondary Dependent </th>
          <th>Enrollee ID</th>
          <th>Age</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{enrollee.phone_number}</td>
          <td>{enrollee.plan_name}</td>
          <td>{enrollee.hospital || "Not Provided"}</td>
        </tr>
      </tbody>
      <thead>
        <tr className={styles["table-row"]}>
          <th>Secondary Dependent </th>
          <th>Enrollee ID</th>
          <th>Age</th>
        </tr>
      </thead>
      <tbody>
        <tr className={styles["table-row"]}>
          <td className={styles["benefits-container"]}>
            {benefits.map((benefit) => (
              <Title key={benefit}>{benefit}</Title>
            ))}
            {!showMore && enrollee.benefits && (
              <button
                className={styles["show-more-btn"]}
                onClick={() => setShowMore(true)}>
                {enrollee.benefits.length - 7 > 0 &&
                  `+${enrollee.benefits.length - 7} More`}
              </button>
            )}
          </td>
          <td>{getFormatedDate(enrollee.date_created, "L")}</td>
          <td>{enrollee.status}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default EnrolleeDependentDetails;
