import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Title from "../../common/Typography/title";
import { BiArrowBack } from "react-icons/bi";
import Button from "../../common/Button";
import Tabs from "../../common/Tabs";
import InfoPanel from "../../common/Panel";
import EnrolleeDetails from "../widget/enrolleeDetails";
import CardRequest from "../widget/cardRequest";
import DrugRefill from "../widget/drugRefill";
import Appointments from "../widget/appointments";
import AuthorizationCode from "../widget/authorizationCode";
import CardRequestDetails from "../components/cardRequestDetails";
import AppointmentsDetails from "../components/appointmentsDetails";
import AuthorizationCodeDetails from "../components/authorizationCode";
import {
  acceptRequest,
  fetchEnrollee,
  getEnrollees,
  updateRequestStatusById,
} from "../../../store/enrollee";
import Alert from "../../common/Alert";

import Loader from "../../common/Loader";
import styles from "./index.module.css";
import ModalAction from "./components/modal";
import DrugRefillRequestDetails from "../components/drugRefillDetails";
import BuyOtherDetails from "../components/buyOthersDetails";
import html2pdf from "html2pdf.js";


const tabData = [
  "Enrollee Details",
  "Card Request",
  "Authorization Code",
  "Drug Refill",
  "Appointments",
];

const AcceptEnrolleeRequest = () => {
  const location = useLocation();

  const [currentEnrollee, setCurrentEnrollee] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState("Enrollee Details");
  const [DetailsComponent, setDetailsComponent] = useState(null);
  const [showRequestPanel, setShowRequestPanel] = useState(false);

  const fromUrlDetailID = location.state && location.state.detailId;

  const [detailId, setDetailId] = useState(fromUrlDetailID || null);
  const [isNewEnrollee, setIsNewEnrollee] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [responseValue, setResponseValue] = useState("");
  const [currentDetail, setCurrentDetail] = useState({});

  const params = useParams();
  const history = useHistory();
  const enrollees = useSelector(getEnrollees);
  const dispatch = useDispatch();
  const [reason, setReason] = useState('');

  const updateTab = (index, component = null, data) => {
    setCurrentDetail(data);
    setTabIndex(index);
    setCurrentTab(tabData[index]);
    setDetailsComponent(component);
  };

  useEffect(() => {
    const getEnrollee = async () => {
      if (params.requestType === "details") setIsNewEnrollee(true);
      await dispatch(fetchEnrollee(params.id));
    };
    getEnrollee();
  }, [dispatch, params]);

  useEffect(() => {
    if (!enrollees.data) history.push("/hmo/dashboard/enrollees/approved");
    else setCurrentEnrollee(enrollees.data);
  }, [params, history, dispatch, enrollees]);

  const getHistoryData = useCallback(
    (field) => {
      return (
        enrollees?.data &&
        enrollees?.data[field]?.find((history) => history.id === detailId)
      );
    },
    [detailId, enrollees]
  );


  useEffect(() => {
    switch (params.requestType) {
      case "details":
        updateTab(0, null, { status: "pending", id: params.id });
        break;
      case "card-request":
        const currentCardRequest = getHistoryData("card_requests_history");
        updateTab(
          1,
          <CardRequestDetails details={currentCardRequest} />,
          currentCardRequest
        );
        break;
      case "for-others":
        //const currentCardRequest = getHistoryData("card_requests_history");
        updateTab(
          5,
          <BuyOtherDetails details={{
            id:1,
            phone_number:'04839383',
            status:'active',

          }} />,
          currentCardRequest
        );
        break;
      case "authorization-code":
        const currentAuthDetails = getHistoryData("auth_code_requests_history");
        updateTab(
          2,
          <AuthorizationCodeDetails details={currentAuthDetails} />,
          currentAuthDetails
        );
        break;
      case "drug-refill":
        const currentDrugRefill = getHistoryData(
          "drug_refill_requests_history"
        );
        updateTab(
          3,
          <DrugRefillRequestDetails details={currentDrugRefill} />,
          currentDrugRefill
        );
        break;
      case "appointments":
        const currentAppointment = getHistoryData(
          "appointment_requests_history"
        );
        updateTab(
          4,
          <AppointmentsDetails details={currentAppointment} />,
          currentAppointment
        );
        break;

      default:
        updateTab(0);
        break;
    }
  }, [params, currentEnrollee, detailId, getHistoryData]);

  const handleRequestType = async (e, type) => {
    e.preventDefault();
    setOpenModal(false);

    switch (params.requestType) {
      case "details":
        if (!responseValue.trim() && type === "approved") return;
        const detailsRes = await dispatch(
          acceptRequest(params.id, { enrollee_id: responseValue })
        );
        handleSuccessNotification(
          detailsRes.payload.status,
          `enrollee sucessfully ${type}`,
          "/hmo/dashboard/enrollees/approved"
        );
        break;
      case "card-request":
        const cardRes = await dispatch(
          updateRequestStatusById(detailId, type, "request-card",{
            decline_reason:reason
          })
        );
        handleSuccessNotification(
          cardRes.payload.status,
          `card Request sucessfully ${type}`,
          `/hmo/dashboard/enrollees/view/card-request/${params.id}`
        );
        break;
      case "drug-refill":
        const drugRefillRes = await dispatch(
          updateRequestStatusById(detailId, type, "drug-refills",{
            decline_reason:reason
          })
        );
        handleSuccessNotification(
          drugRefillRes.payload.status,
          `Drug Refills Request sucessfully ${type}`,
          `/hmo/dashboard/enrollees/view/drug-refill/${params.id}`
        );
        break;
      case "authorization-code":
        if (!responseValue.trim() && type === "approved") return;
        const authCodeRes = await dispatch(
          acceptRequest(
            detailId,
            { request_code: responseValue,decline_reason:reason },
            "request-code",
            `/${type}`
          )
        );
        handleSuccessNotification(
          authCodeRes.payload.status,
          `Authorization code sucessfully ${type}`,
          `/hmo/dashboard/enrollees/view/authorization-code/${params.id}`
        );
        break;
      case "appointments":
        const appointmentRes = await dispatch(
          updateRequestStatusById(detailId, type, "hospital-appointments",
            {
              decline_reason:reason
            }
          )
        );
        handleSuccessNotification(
          appointmentRes.payload.status,
          `appointments sucessfully ${type}`,
          `/hmo/dashboard/enrollees/view/appointments/${params.id}`
        );
        break;

      default:
        break;
    }
  };
  const handleShowDetails = (status, id, url) => {
    setShowRequestPanel(status);
    setDetailId(id);
    history.push(`/hmo/dashboard/enrollee/${url}/accept/${params.id}`);
    window.scrollTo(0, 0);
  };

  const handleSuccessNotification = async (status, message, redirectUrl) => {
    if (status) await Alert.notication("success", message);
    history.push(redirectUrl);
  };
  const handleSetApprove = () => {
    setRequestType("approve");
    setOpenModal(true);
  };
  const handleDeclineRequest = () => {
    setRequestType("decline");
    setOpenModal(true);
  };

  const requestBodyTypes = ["details", "authorization-code"];
  const labelsObj = {
    details: "Enrollee ID",
    "authorization-code": "Authorization Code",
  };

   const handleDownload = () => {
          const element = document.getElementById("pdf-content");
          const opt = {
            margin: 1,
            filename: "my_page.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          };
      
          html2pdf().set(opt).from(element).save();
        };

  return (
    <div className={styles.container}>
      <Loader visible={enrollees.loading} />

      <ModalAction
        open={openModal}
        onClose={() => setOpenModal(false)}
        enrolleeName={currentEnrollee?.enrollee_details?.name}
        onInputChange={(value) => setResponseValue(value.toUpperCase())}
        inputValue={responseValue}
        onApprove={(e) => handleRequestType(e, "approved")}
        onDecline={(e) => handleRequestType(e, "declined")}

        onCancel={() => setOpenModal(false)}
        request={requestType}
        requiresInput={requestBodyTypes.includes(params.requestType)}
        label={
          labelsObj[
            requestBodyTypes.find((type) => type === params.requestType)
          ]
        }
        requestName={params.requestType}
        reason={reason}
        setReason={setReason}
      />

<button className="pdf-download" onClick={handleDownload}>
          Download as PDF
        </button>
        <div id="pdf-content">
      <div className="flex items-center  justify-between mb-5">
        <div className="flex items-center">
          <button className="btn-round-small" onClick={() => history.goBack()}>
            <BiArrowBack size="1.2em" />
          </button>
          <Title style={{ fontSize: "16px" }}>Back</Title>
        </div>
        {currentDetail && currentDetail.status === "pending" && (
          <div>
            <Button onClick={handleSetApprove}>Approve</Button>
            <Button
              variant="mutted"
              className="ml-2"
              onClick={handleDeclineRequest}>
              Decline
            </Button>
          </div>
        )}
      </div>

      {showRequestPanel && DetailsComponent}
      <InfoPanel />

      <div style={{ margin: "2rem 0" }}>
        <Paper>
          <Tabs
            activeTab={currentTab}
            tabData={tabData}
            tabIndex={tabIndex}
            onTabChange={(index) => setTabIndex(index)}
            disableTabs={isNewEnrollee}
          />

          <div className={styles["tab-panel-body"]}>
            <TabPanel value={0} index={tabIndex}>
              <EnrolleeDetails />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <CardRequest onShowCardDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={2} index={tabIndex}>
              <AuthorizationCode onShowAuthDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={3} index={tabIndex}>
              <DrugRefill onShowDrugRefillDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={4} index={tabIndex}>
              <Appointments onShowAppointmentDetails={handleShowDetails} />
            </TabPanel>
          </div>
        </Paper>
      </div>
      </div>
    </div>
  );
};

export default AcceptEnrolleeRequest;
