import React from "react";
import { useSelector } from "react-redux";
import { getEnrollees } from "../../../store/enrollee";
import { getFormatedDate } from "../../../utils/constants";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Tabs from "../../common/Tabs";
import styles from "./index.module.css";

const AuthorizationCodeDetail = ({ details }) => {
  const enrollees = useSelector(getEnrollees);
  if (!details) return null;

  const enrollee = enrollees.data ? enrollees.data.enrollee_details : {};

  return (
    <Paper className="mb-5">
      <Tabs
        activeTab={"Request Details"}
        tabData={["Request Details", "", "", "", ""]}
        tabIndex={0}
        onTabChange={(index) => console.log(index)}
      />

      <div className={styles["tab-panel-body"]}>
        <TabPanel value={0} index={0}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Enrollee ID</th>
                <th>Enrollee Name</th>
                <th>Enrollee email</th>
                <th>Phone Number</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{enrollee.enrollee_id}</td>
                <td>{enrollee.name}</td>
                <td style={{ textTransform: "none" }}>{enrollee.email}</td>
                <td style={{ textTransform: "none" }}>{enrollee.phone_number}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Hospital Name</th>
                <th>Authorization Code</th>
                <th>Request</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details.hospital_name}</td>
                <td>{details.request_code || "Nill"}</td>
                <td>{details.request_message}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Date Requested</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr className={styles["table-row"]}>
                <td>{getFormatedDate(details.created_at)}</td>
                <td>{details.status}</td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
      </div>
    </Paper>
  );
};

export default AuthorizationCodeDetail;
