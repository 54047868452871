import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "dental-care",
  initialState: {
    activeDentalCareRequestList: [],
    pendingDentalCareList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedUserDentalCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.activeDentalCareRequestList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedPendingUserDentalCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.pendingDentalCareList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedUserDentalCareRequestStart: (user, action) => {
      user.loading = true;
    },
    fetchedUserDentalCareRequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

const {
  fetchedUserDentalCareRequestSuccess,
  fetchedPendingUserDentalCareRequestSuccess,
  fetchedUserDentalCareRequestStart,
  fetchedUserDentalCareRequestFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v1/admin/appointments/dental";

export const fetchDentalCareUsersRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedUserDentalCareRequestSuccess.type,
    onStart: fetchedUserDentalCareRequestStart.type,
    onError: fetchedUserDentalCareRequestFailed.type,
  });

export const fetchDentalCareUserPendingRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingUserDentalCareRequestSuccess.type,
    onStart: fetchedUserDentalCareRequestStart.type,
    onError: fetchedUserDentalCareRequestFailed.type,
  });

export const getDentalCareUsers = (state) => state.entities.dentalCareUsers;

export const getPendingDentalCareUser = (state) =>
  state.entities.dentalCareUsers.pendingDentalCareList;

export const getPendingDentalUserCareById = (state) => (id) =>
  state.entities.dentalCareUsers.pendingDentalCareList.find((e) => e.id === id);
