import React from "react";
import Modal from "../../../common/Modal";
import styles from "../index.module.css";
import Button from "../../../common/Button";

// const DeclineRequest = ({ title, onChangeMessage, onSubmit, type }) => {
//   return (
//     <form className={styles["modal-container"]}>
//       <h1>{title}</h1>

//       <div className={styles["input-container"]}>
//         <label>Reason For Rejection</label>
//         <textarea
//           rows={4}
//           onChange={({ target }) => onChangeMessage(target.value)}
//           className={styles["textarea"]}
//         ></textarea>

//         <div className="mt-3 flex items-center justify-between">
//           <Button onClick={onSubmit} type="submit" className={styles["decline-btn"]}>
//             Decline {type}
//           </Button>
//         </div>
//       </div>
//     </form>
//   );
// };

const ProceeRequest = ({ onClick, onCancel, onInputChange, requestType, title, label, value, requiresInput,reason, setReason }) => {
  console.log(requestType)
  return (
    <form className={styles["modal-container"]}>
      <h1>{title}</h1>

      <div className={styles["input-container"]}>
        {requestType === "decline" && (
          <div>
            <label>state your reason?</label>
            <input
              type="text"
              value={reason}
              className={styles["input"]}
              onChange={({ target }) => setReason(target.value)}
            />
          </div>
        )}
        {requiresInput && requestType === "approve" && (
          <div>
            <label>{label}</label>
            <input
              type="text"
              value={value}
              className={styles["input"]}
              onChange={({ target }) => onInputChange(target.value)}
            />
          </div>
        )}

        <div className="mt-3 flex items-center justify-between">
          <Button onClick={onClick}>Yes, Proceed</Button>
          <Button variant="mutted" onClick={onCancel}>
            No, Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

const formatRequestName = (name) => {
  if (!name) return name;
  return name.split("-").join(" ");
};

const getLabelRequestName = (label, name) => {
  if (!label || !name) return formatRequestName(name);
  return label.toLowerCase() !== formatRequestName(name).toLowerCase() && label === "details"
    ? formatRequestName(name)
    : "request";
};
const ModalAction = ({
  // uiTexts = {},
  requiresInput,
  open,
  onClose,
  enrolleeName,
  onInputChange,
  onCancel,
  request,
  label,
  inputValue,
  onApprove = () => {},
  onDecline = () => {},
  requestName = "",
  reason="",
  setReason=() => {}

  // onChangeDeclineMessage = () => {},
}) => {

  console.log(requestName);
  return (
    <Modal open={open} close={onClose}>
      {/* {request === "approve" ? ( */}
      <ProceeRequest
        onClick={request === "approve" ? onApprove : onDecline}
        onCancel={onCancel}
        onInputChange={onInputChange}
        enrolleeName={enrolleeName}
        value={inputValue}
        label={label}
        requestType={request}
        requiresInput={requiresInput}
        title={
          <span>
            Would you like to {request} <strong>{enrolleeName}</strong> {label}{" "}
            {getLabelRequestName(label, requestName)}?
          </span>
        }
        input={
          <input placeholder="state your reason" style={{width:'100%'}} />
        }
        requestName={requestName}
        reason={reason}
        setReason={setReason}
      />
      {/* ) : (
        <DeclineRequest
          title={uiTexts.title}
          onChangeMessage={onChangeDeclineMessage}
          onSubmit={onDecline}
          type={"enrollee"}
        />
      )} */}
    </Modal>
  );
};

export default ModalAction;
