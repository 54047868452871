import React, { useState } from "react";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Tabs from "../../common/Tabs";
import Title from "../../common/Typography/title";
import { getUsers } from "../../../store/user";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";
import styles from "./index.module.css";
import html2pdf from "html2pdf.js";


const RequestLogDetails = ({ }) => {
  const details = JSON.parse(localStorage.getItem('requestlog'));
  const users = useSelector(getUsers);
  const [showMore, setShowMore] = useState(false);
  const user = users?.data ? users.data.user_data : {};
  const services =
    (details?.request_type == "basic" || details?.request_type == "basic_plan") ?
      ((JSON.parse(details?.request_details?.services || details?.request_details?.basic_plan_demographics))) :
      (details?.request_type == "optical" || details?.request_type == "dental" || details?.request_type == "bc" ||
        details?.request_type == "pe" || details?.request_type == "csw" || details?.request_type == "csm"
        || details?.request_type == "cancer" ||
        details?.request_type == "wp"
        || details?.request_type == "wb"
        || details?.request_type == "mb"
        || details?.request_type == "mp"
        || (details?.request_type == "home_visit")
        || (details?.request_type == "appointment")
        || (details?.request_type == "instant_call")
      ) ?
        ((details?.request_details?.services?.fields || [''])) :
        (details?.request_type == "custom_plan" || details?.request_type == "custom") && details?.request_details?.benefits
    ;

  console.log(services);

const handleDownload = () => {
      const element = document.getElementById("pdf-content");
      const opt = {
        margin: 1,
        filename: "my_page.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
  
      html2pdf().set(opt).from(element).save();
    };


  console.log(details);
  return (
    <Paper className="mb-5">
       <button className="pdf-download" onClick={handleDownload}>
          Download as PDF
        </button>
        <div id="pdf-content">
      <Tabs
        activeTab={"Request Details"}
        tabData={["Request Details", "", "", "", ""]}
        tabIndex={0}
        onTabChange={(index) => console.log(index)}
      />

      <div className={styles["tab-panel-body"]}>
        <TabPanel value={0} index={0}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>User Name</th>
                <th>Request type</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details?.id}</td>
                <td>{details?.user_details?.first_name} {' '} {details?.user_details?.last_name}</td>
                <td>
                  {(details?.request_type == "basic" || details?.request_type == "basic_plan") && 'Basic Plan'}
                  {details?.request_type == "optical" && 'Optical care'}
                  {details?.request_type == "dental" && 'Dental care'}
                  {details?.request_type == "bc" && 'Basic checkup'}
                  {
                    (details?.request_type == 'custom' || details?.request_type == 'custom_plan') && 'Custom plan'
                  }
                  {
                    details?.request_type == "pe" && 'Pre-employment'
                  }
                  {
                    ((details?.request_type == "csw") || (details?.request_type == "csm")
                      || (details?.request_type == "cancer")) && 'Cancer test'
                  }
                  {
                    ((details?.request_type == "wp")
                      || (details?.request_type == "wb")
                      || (details?.request_type == "mb")
                      || (details?.request_type == "mp")
                      || (details?.request_type == 'comprehensive')
                    ) && `Comprehensive test (${details?.request_type})`
                  }
                  {
                    (details?.request_type == "home_visit") && 'Home Visit'
                  }
                  {
                    (details?.request_type == "appointment") && 'Appointment'
                  }
                  {

                    (details?.request_type == "instant_call") && 'Instant call'

                  }
                </td>

              </tr>
            </tbody>

            {
              ((details?.request_type == "basic" || details?.request_type == "basic_plan") ||
                (details?.request_type == "optical") || (details?.request_type == "dental") || (details?.request_type == "bc") ||
                (details?.request_type == 'custom') || (details?.request_type == 'custom_plan') || (details?.request_type == "pe")
                || (details?.request_type == "csw") || (details?.request_type == "csm")
                || (details?.request_type == "cancer")
                || (details?.request_type == "wp")
                || (details?.request_type == "wb")
                || (details?.request_type == "mb")
                || (details?.request_type == "mp")
                || (details?.request_type == "home_visit")
                || (details?.request_type == "appointment")
                || (details?.request_type == "instant_call")

              )
              &&
              <>
                <thead>
                  <tr className={styles["table-row"]}>
                    <th>Amount to be paid</th>
                    <th>Transaction ID</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{details?.request_details?.amount_to_be_paid || details?.request_details?.amount_paid || details?.request_details?.amount}</td>
                    {/* <td>{details?.user_details?.first_name} {' '} {details?.user_details?.last_name}</td>
                <td>{details?.request_type}</td> */}
                    <td>{details?.request_details?.transaction_id || details?.request_details?.payment_ref}</td>

                  </tr>
                </tbody>
              </>

            }


            {
              (
                (details?.request_type == "optical") || (details?.request_type == "dental") || (details?.request_type == "bc")
                || (details?.request_type == 'custom') || (details?.request_type == 'custom_plan')
                || (details?.request_type == "pe") || (details?.request_type == "csw") || (details?.request_type == "csm")
                || (details?.request_type == "cancer")
                || (details?.request_type == "wp")
                || (details?.request_type == "wb")
                || (details?.request_type == "mb")
                || (details?.request_type == "mp")
                || (details?.request_type == "home_visit")
                || (details?.request_type == "appointment")
                || (details?.request_type == "instant_call")
              ) &&
              <>
                <thead>
                  <tr className={styles["table-row"]}>
                    <th>Appointment date</th>
                    <th>Hospital</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{getFormatedDate(details?.request_details?.appointment_date)}</td>
                    {/* <td>{details?.user_details?.first_name} {' '} {details?.user_details?.last_name}</td>
                <td>{details?.request_type}</td> */}
                    <td>
                      <div>{details?.request_details?.hospital_name}</div>
                      <div>{details?.request_details?.hospital_address}</div>
                      <div>{details?.request_details?.hospital_location}</div>

                    </td>

                  </tr>
                </tbody>
              </>

            }


            {
              (
                (details?.request_details?.user_type == "others")
                || (details?.request_details?.user_type == "other")) &&
              <>
                <thead>
                  <tr className={styles["table-row"]}>
                    <th>Bought For</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div>{details?.request_details?.first_name} {details?.request_details?.last_name}</div>
                      <div>{details?.request_details?.email}</div>

                    </td>

                  </tr>
                </tbody>
              </>

            }


            <thead>
              <tr className={styles["table-row"]}>
                <th>Status</th>
                <th>Created</th>


              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details?.status}</td>
                <td>
                  {getFormatedDate(
                    details?.data?.created
                  )}
                </td>


              </tr>
            </tbody>
            {
              ((details?.request_type != 'custom') && (details?.request_type != 'custom_plan')) &&
              <>
                <thead>
                  <tr className={styles["table-row"]}>
                    <th>Services</th>

                  </tr>
                </thead>

                <tbody>
                  <tr>

                    <td>{services.map((d, i) => {
                      return (
                        <div>{d}</div>
                      )
                    })}
                    </td>

                  </tr>
                </tbody>
              </>
            }


            {
              ((details?.request_type == 'custom') || (details?.request_type == 'custom_plan')) &&
              <>
                <thead>
                  <tr className={styles["table-row"]}>
                    <th>Services</th>

                  </tr>
                </thead>

                <tbody>
                  <tr>

                    <td>{services.fields.length > 0 && services?.fields.map((d, i) => {
                      return (
                        <div>
                          {d} {'-'} {services.values[i]}
                        </div>
                      )
                    })}
                    </td>

                  </tr>
                </tbody>
              </>
            }
          </table>
        </TabPanel>
      </div>
      </div>
    </Paper>
  );
};

export default RequestLogDetails;
