import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "hospital",
  initialState: {
    activeHospitalList: [],
    deleteHopitalList: [],
    hospitalLevelList: [],
    createHospital: null,
    loading: false,
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    data: {},
  },
  reducers: {
    fetchedHospitalSuccess: (hospital, action) => {
      hospital.loading = false;
      hospital.activeHospitalList = action.payload.data.data;
      hospital.total = action.payload.data.total;
      hospital.currentPage = action.payload.data.from;
      hospital.toPage = action.payload.data.to;
      hospital.lastPage = action.payload.data.last_page;
    },
    fetchedHospitalStart: (hospital) => {
      hospital.loading = true;
    },
    fetchedHospitalFailed: (hospital) => {
      hospital.loading = false;
    },
    fetchedDeletedSuccess: (hospital, action) => {
      hospital.loading = false;
      hospital.deleteHopitalList = action.payload.data.data;
      hospital.total = action.payload.data.total;
      hospital.currentPage = action.payload.data.from;
      hospital.toPage = action.payload.data.to;
      hospital.lastPage = action.payload.data.last_page;
    },
    fetchedDeletedStart: (hospital) => {
      hospital.loading = true;
    },
    fetchedDeletedFailed: (hospital) => {
      hospital.loading = false;
    },
    fetchedHospitalLevelSuccess: (hospital, action) => {
      hospital.loading = false;
      hospital.hospitalLevelList = action.payload.data;
    },
    fetchedHospitalLevelStart: (hospital) => {
      hospital.loading = true;
    },
    fetchedHospitalLevelFailed: (hospital) => {
      hospital.loading = false;
    },
    createHospitalSuccess: (hospital) => {
      hospital.loading = false;
    },
    createHospitalStart: (hospital) => {
      hospital.loading = true;
    },
    createHospitalFailed: (hospital) => {
      hospital.loading = false;
    },
    editHospitalSuccess: (hospital) => {
      hospital.loading = false;
    },
    deleteHospitalSuccess: (hospital) => {
      hospital.loading = false;
    },
  },
});

const {
  fetchedHospitalSuccess,
  fetchedHospitalStart,
  fetchedHospitalFailed,
  fetchedDeletedSuccess,
  fetchedDeletedStart,
  fetchedDeletedFailed,
  fetchedHospitalLevelSuccess,
  fetchedHospitalLevelStart,
  fetchedHospitalLevelFailed,
  createHospitalSuccess,
  createHospitalStart,
  createHospitalFailed,
  editHospitalSuccess,
  deleteHospitalSuccess,
} = slice.actions;

export default slice.reducer;

const url = "/v1/admin";

export const fetchHospital = () =>
  apiCallBegan({
    url: `${url}/hospitals`,
    method: "GET",
    onSuccess: fetchedHospitalSuccess.type,
    onStart: fetchedHospitalStart.type,
    onError: fetchedHospitalFailed.type,
  });

export const fetchDeletedEnrollees = () =>
  apiCallBegan({
    url: `${url}/hospitals`,
    method: "GET",
    onSuccess: fetchedDeletedSuccess.type,
    onStart: fetchedDeletedStart.type,
    onError: fetchedDeletedFailed.type,
  });

export const fetchHospitalLevel = () =>
  apiCallBegan({
    url: `${url}/hospital-levels/`,
    method: "GET",
    onSuccess: fetchedHospitalLevelSuccess.type,
    onStart: fetchedHospitalLevelStart.type,
    onError: fetchedHospitalLevelFailed.type,
  });
export const createHospital = (data) =>
  apiCallBegan({
    url: `${url}/hospitals`,
    method: "POST",
    data,
    onSuccess: createHospitalSuccess.type,
    onStart: createHospitalStart.type,
    onError: createHospitalFailed.type,
  });
/**
 *
 * @param {number} id {id of the editHospital}
 */
export const editHospital = (values) =>
  apiCallBegan({
    url: `${url}/hospitals/${values.editId}`,
    method: "PATCH",
    data: {
      name: values.editingName,
      address: values.editingAddress,
      location: values.editingLocation,
      plan: values.editingItem,
      level: values.level,
    },
    // values,
    onSuccess: editHospitalSuccess.type,
    onStart: fetchedHospitalStart.type,
    onError: fetchedHospitalFailed.type,
  });

/**
 *
 * @param {number} id {id of the deleteHospital}
 */
export const deleteHospital = (id) =>
  apiCallBegan({
    url: `${url}/hospitals/${id}`,
    method: "DELETE",
    onSuccess: deleteHospitalSuccess.type,
    onStart: fetchedHospitalStart.type,
    onError: fetchedHospitalFailed.type,
  });

export const getHospital = (state) => state.entities.hospital;
