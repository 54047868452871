import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "serviceProvider",
  initialState: {
    serviceProviderList: [],
    loading: false,
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    data: {},
  },
  reducers: {
    fetchedServiceProviderSuccess: (serviceProvider, action) => {
      serviceProvider.loading = false;
      serviceProvider.serviceProviderList = action.payload.data.data;
      serviceProvider.total = action.payload.data.total;
      serviceProvider.currentPage = action.payload.data.from;
      serviceProvider.toPage = action.payload.data.to;
      serviceProvider.lastPage = action.payload.data.last_page;
    },
    fetchedServiceProviderStart: (serviceProvider) => {
      serviceProvider.loading = true;
    },
    fetchedServiceProviderFailed: (serviceProvider) => {
      serviceProvider.loading = false;
    },
    createServiceProviderSuccess: (serviceProvider) => {
      serviceProvider.loading = false;
    },
    createServiceProviderStart: (serviceProvider) => {
      serviceProvider.loading = true;
    },
    createServiceProviderFailed: (serviceProvider) => {
      serviceProvider.loading = false;
    },
    editServiceProviderSuccess: (serviceProvider) => {
      serviceProvider.loading = false;
    },
    deleteServiceProviderSuccess: (serviceProvider) => {
      serviceProvider.loading = false;
    },
  },
});

const {
  fetchedServiceProviderSuccess,
  fetchedServiceProviderStart,
  fetchedServiceProviderFailed,
  createServiceProviderSuccess,
  createServiceProviderStart,
  createServiceProviderFailed,
  editServiceProviderSuccess,
  deleteServiceProviderSuccess,
} = slice.actions;

export default slice.reducer;

const url = "/v1/admin";

export const fetchServiceProvider = () =>
  apiCallBegan({
    url: `${url}/health-service-providers`,
    method: "GET",
    onSuccess: fetchedServiceProviderSuccess.type,
    onStart: fetchedServiceProviderStart.type,
    onError: fetchedServiceProviderFailed.type,
  });
export const createServiceProvider = (data) =>
  apiCallBegan({
    url: `${url}/health-service-providers`,
    method: "POST",
    data,
    onSuccess: createServiceProviderSuccess.type,
    onStart: createServiceProviderStart.type,
    onError: createServiceProviderFailed.type,
  });
/**
 *
 * @param {number} editId {id of the editing Service Provider}
 */
export const editServiceProvider = ({
  editId,
  name,
  address,
  location,
  service_name,
  service_id,
}) =>
  apiCallBegan({
    url: `${url}/health-service-providers/${editId}`,
    method: "PATCH",
    data: {
      name,
      address,
      location,
      service_name,
      service_id,
    },
    onSuccess: editServiceProviderSuccess.type,
    onStart: fetchedServiceProviderStart.type,
    onError: fetchedServiceProviderFailed.type,
  });
/**
 *
 * @param {number} id {id of the deleteServiceProvider}
 */
export const deleteServiceProvider = (id) =>
  apiCallBegan({
    url: `${url}/health-service-providers/${id}`,
    method: "DELETE",
    onSuccess: deleteServiceProviderSuccess.type,
    onStart: fetchedServiceProviderStart.type,
    onError: fetchedServiceProviderFailed.type,
  });
export const getServiceProvider = (state) => state.entities.serviceProvider;
