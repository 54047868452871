import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardData: {},
    loading: false,
  },
  reducers: {
    fetchedDashboardDataSuccess: (dashboard, action) => {
      dashboard.loading = false;
      dashboard.dashboardData = action.payload.data;
    },

    fetchedDashboardStart: (dashboard, action) => {
      dashboard.loading = true;
    },
    fetchedDashboardFailed: (dashboard, action) => {
      dashboard.loading = false;
    },
  },
});

const {
  fetchedDashboardDataSuccess,
  fetchedDashboardStart,
  fetchedDashboardFailed,
} = slice.actions;

export default slice.reducer;

export const fetchDashboardData = () =>
  apiCallBegan({
    url: "/v1/admin/dashboard",
    onSuccess: fetchedDashboardDataSuccess.type,
    onStart: fetchedDashboardStart.type,
    onError: fetchedDashboardFailed.type,
  });

export const fetchWellnessOnTheGoDashboardData = () =>
  apiCallBegan({
    url: "/v1/admin/dashboard",
    onSuccess: fetchedDashboardDataSuccess.type,
    onStart: fetchedDashboardStart.type,
    onError: fetchedDashboardFailed.type,
  });

export const getDashboardData = (state) => state.entities.dashboard;
