import React from "react";
import PropTypes from "prop-types";
import { dashboardLayoutStyles } from "../../styles/dashboardStyle";
import html2pdf from "html2pdf.js";

const DashboardLayout = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  middleLeft,
  middleRight,
}) => {
  const classes = dashboardLayoutStyles();

 
    const handleDownload = () => {
      const element = document.getElementById("pdf-content");
      const opt = {
        margin: 1,
        filename: "my_page.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
  
      html2pdf().set(opt).from(element).save();
    };
  
  return (
    <div className={classes.dashboard}>
       <button className="pdf-download" onClick={handleDownload}>
          Download as PDF
        </button>
      {/* top dashboard */}
      <div id="pdf-content">
      <div  className={classes.topDashboard}>
        {topLeft && <div className={classes.topLeft}>{topLeft}</div>}

        {topRight && <div className={classes.topRight}>{topRight}</div>}
      </div>
      {/* middle dashboard */}
      <div className={classes.middleDashboard}>
        {middleLeft && (
          <div className={classes.middleLeftDashboard}>{middleLeft}</div>
        )}
        {middleRight && (
          <div className={classes.middleRightDashboard}>{middleRight}</div>
        )}
      </div>

      {/* bottom dashboard */}
      <div className={classes.bottomDashboard}>
        {bottomLeft && (
          <div className={classes.bottomLeftDashboard}>{bottomLeft}</div>
        )}

        {bottomRight && (
          <div className={classes.bottomRightDashboard}>{bottomRight}</div>
        )}
      </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  topLeft: PropTypes.node,
  topRight: PropTypes.node,
  bottomLeft: PropTypes.node,
  bottomRight: PropTypes.node,
};

export default DashboardLayout;
